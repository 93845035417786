<template>
  <v-app>
    <v-container grid-list-xs fluid>
      <v-snackbar
        v-model="snackbar"
        color="secondary"
        right
        top
        rounded
        :timeout="-1"
      >
        {{ msg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-navigation-drawer v-model="drawer" app>
        <v-list dense rounded>
          <!-- <v-list-item to="/setup">
            <v-list-item-action>
              <v-icon>mdi-settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{ $t("set-up") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item to="/account">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{ $t("account") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/about">
            <v-list-item-action>
              <v-icon>mdi-help-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{ $t("about-session") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toManage">
            <v-list-item-action>
              <v-icon> mdi-account-supervisor-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("conferenceManage") }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/setup">
            <v-list-item-action>
              <v-icon> mdi-gavel</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> {{ $t("offlineChair") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <div class="mx-3">
            <p class="grey--text lighten-3">
              Made with
              <v-icon color="primary" class="love">mdi-heart</v-icon> by
              MUNSHARE
            </p>
          </div>
        </template>
      </v-navigation-drawer>
      <v-app-bar app height="70">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          {{ currentSession.committee }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title class="mr-2" v-if="!this.$store.getters.isPro">
          {{ $t("munshare") }} Session
        </v-toolbar-title>
        <v-toolbar-title class="mr-2" v-else>
          <div v-if="this.$store.state.showSession">Session Pro</div>
        </v-toolbar-title>
        <v-toolbar-title class="mr-2">
          {{ this.$store.state.conferenceName }}
        </v-toolbar-title>
        <v-menu close-on-click offset-y v-if="this.$store.state.user">
          <template v-slot:activator="{ on }">
            <v-avatar size="60" v-on="on">
              <v-img :src="orgAvatar" :alt="orgName"></v-img>
            </v-avatar>
          </template>
          <v-card width="200">
            <v-card-title class="justify-center">
              <div class="caption font-weight-medium">{{ orgName }}</div>
            </v-card-title>
            <v-divider class="mx-2"></v-divider>
            <v-list dense>
              <v-list-item to="/account">
                <v-list-item-subtitle>个人中心</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="toDashboard">
                <v-list-item-subtitle>模时账户</v-list-item-subtitle>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-subtitle>退出</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>

        <v-btn color="primary" icon @click="fullScreen">
          <v-icon large> mdi-fullscreen </v-icon>
        </v-btn>
      </v-app-bar>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  components: {},
  metaInfo() {
    return {
      title: this.$t("immediateUse"),
      titleTemplate: "%s | Munshare Session",
    };
  },
  data() {
    return {
      drawer: null,
      onLine: navigator.onLine,
      snackbar: false,
      msg: "",
    };
  },
  watch: {
    onLine(v) {
      if (v) {
        this.snackbar = true;
        this.$store.dispatch("updateSession");
        this.msg = "恭喜你重新上线，数据已经被储存啦🥳";
      } else {
        this.snackbar = true;
        this.msg =
          "没有网络连接？不用担心，会议数据储存在本地，上线后便会自动保存💪";
      }
    },
  },
  computed: {
    ...mapState(["currentSession"]),
    orgAvatar() {
      return this.$store.state.user.avatar;
    },
    orgName() {
      return this.$store.state.user.name_zh;
    },
    munid() {
      return `http://www.munshare.com/munid/` + this.$store.state.user.objectId;
    },
  },
  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === "online";
    },
    fullScreen() {
      var elem = document.documentElement;
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    },
    toDashboard() {
      window.open("https://www.munshare.com");
    },
    toManage() {
      window.open("https://www.munshare.com/conferenceManagement", "_blank");
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
  mounted() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
    const theme = this.$store.state.theme;
    this.$vuetify.theme.themes.light.primary = theme;
    this.$vuetify.theme.themes.dark.primary = theme;
  },
  beforeDestroy() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>


<style  scoped>
.love {
  display: inline-block;
  position: relative;
  font-size: 1.4em;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-animation: love 0.5s infinite linear alternate-reverse;
  -moz-animation: love 0.5s infinite linear alternate-reverse;
  animation: love 0.5s infinite linear alternate-reverse;
}
@-webkit-keyframes love {
  to {
    -webkit-transform: scale(1.2);
  }
}
@-moz-keyframes love {
  to {
    -moz-transform: scale(1.2);
  }
}
@keyframes love {
  to {
    transform: scale(1.2);
  }
}
</style>
<i18n>
{
  "en": {
    "set-up": "Conference Chair",
    "account": "Account",
    "reset": "Reset",
    "resetConfirm":"Are you sure to clear data and restart?",
    "about-session": "About Session",
    "about-munshare": "About Munshare",
    "immediateUse":"Chair MUN Online",
    "munshare": "Munshare",
    "offlineChair":"Offline Chair",
    "conferenceManage":"Conference Management"

  },
  "zh": {
    "set-up": "主持会议",
    "account": "用户中心",
    "reset": "重置",
    "resetConfirm":"确定清除所有数据并重启App么？",
    "about-session": "关于模时Session",
    "about-munshare": "关于模时",
    "munshare": "模时",
    "immediateUse":"随开随用的模联议程",
    "offlineChair":"离线主持",
    "conferenceManage":"会议全周期管理"
  }
}
</i18n>
