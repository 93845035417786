const countries = [
  {
    "code": "AFG",
    "name_zh": "阿富汗",
    "name_en": "Afghanistan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ALB",
    "name_zh": "阿尔巴尼亚",
    "name_en": "Albania",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ARG",
    "name_zh": "阿根廷",
    "name_en": "Argentina",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AUS",
    "name_zh": "澳大利亚",
    "name_en": "Australia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DZA",
    "name_zh": "阿尔及利亚",
    "name_en": "Algeria",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AND",
    "name_zh": "安道尔",
    "name_en": "Andorra",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AGO",
    "name_zh": "安哥拉",
    "name_en": "Angola",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AIA",
    "name_zh": "安圭拉",
    "name_en": "Anguilla",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ATG",
    "name_zh": "安提瓜和巴布达",
    "name_en": "Antigua and Barbuda",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ARM",
    "name_zh": "亚美尼亚",
    "name_en": "Armenia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ABW",
    "name_zh": "阿鲁巴",
    "name_en": "Aruba",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AUT",
    "name_zh": "奥地利",
    "name_en": "Austria",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "AZE",
    "name_zh": "阿塞拜疆",
    "name_en": "Azerbaijan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BHS",
    "name_zh": "巴哈马",
    "name_en": "Bahamas",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BHR",
    "name_zh": "巴林",
    "name_en": "Bahrain",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BGD",
    "name_zh": "孟加拉",
    "name_en": "Bangladesh",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BRB",
    "name_zh": "巴巴多斯",
    "name_en": "Barbados",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BLR",
    "name_zh": "白俄罗斯",
    "name_en": "Belarus",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BEL",
    "name_zh": "比利时",
    "name_en": "Belgium",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },

  {
    "code": "BLZ",
    "name_zh": "伯利兹",
    "name_en": "Belize",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BEN",
    "name_zh": "贝宁",
    "name_en": "Benin",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BRA",
    "name_zh": "巴西",
    "name_en": "Brazil",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BMU",
    "name_zh": "百慕大",
    "name_en": "Bermuda",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BTN",
    "name_zh": "不丹",
    "name_en": "Bhutan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BOL",
    "name_zh": "玻利维亚",
    "name_en": "Bolivia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BIH",
    "name_zh": "波斯尼亚和黑塞哥维那",
    "name_en": "Bosnia and Herzegovina",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BWA",
    "name_zh": "博茨瓦纳",
    "name_en": "Botswana",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BRN",
    "name_zh": "文莱",
    "name_en": "Brunei",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BGR",
    "name_zh": "保加利亚",
    "name_en": "Bulgaria",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BFA",
    "name_zh": "布基纳法索",
    "name_en": "Burkina Faso",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "BDI",
    "name_zh": "布隆迪",
    "name_en": "Burundi",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KHM",
    "name_zh": "柬埔寨",
    "name_en": "Cambodia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CMR",
    "name_zh": "喀麦隆",
    "name_en": "Cameroon",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CAN",
    "name_zh": "加拿大",
    "name_en": "Canada",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CPV",
    "name_zh": "佛得角",
    "name_en": "Cape Verde",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CAF",
    "name_zh": "中非",
    "name_en": "Central African Republic",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TCD",
    "name_zh": "乍得",
    "name_en": "Chad",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CHL",
    "name_zh": "智利",
    "name_en": "Chile",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CHN",
    "name_zh": "中国",
    "name_en": "China",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "COL",
    "name_zh": "哥伦比亚",
    "name_en": "Colombia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "COM",
    "name_zh": "科摩罗",
    "name_en": "Comoros",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "COK",
    "name_zh": "库克群岛",
    "name_en": "Cook Islands",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CRI",
    "name_zh": "哥斯达黎加",
    "name_en": "Costa Rica",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "HRV",
    "name_zh": "克罗地亚",
    "name_en": "Croatia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CUB",
    "name_zh": "古巴",
    "name_en": "Cuba",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CYP",
    "name_zh": "塞浦路斯",
    "name_en": "Cyprus",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CZE",
    "name_zh": "捷克",
    "name_en": "Czech Republic",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "COD",
    "name_zh": "刚果民主共和国",
    "name_en": "DR Congo",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DNK",
    "name_zh": "丹麦",
    "name_en": "Denmark",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DJI",
    "name_zh": "吉布提",
    "name_en": "Djibouti",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DMA",
    "name_zh": "多米尼克",
    "name_en": "Dominica",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DOM",
    "name_zh": "多米尼加",
    "name_en": "Dominican Republic",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TLS",
    "name_zh": "东帝汶",
    "name_en": "East Timor",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ECU",
    "name_zh": "厄瓜多尔",
    "name_en": "Ecuador",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "EGY",
    "name_zh": "埃及",
    "name_en": "Egypt",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "EU",
    "name_zh": "欧盟",
    "name_en": "European Union",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SLV",
    "name_zh": "萨尔瓦多",
    "name_en": "El Salvador",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GNQ",
    "name_zh": "赤道几内亚",
    "name_en": "Equatorial Guinea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ERI",
    "name_zh": "厄立特里亚",
    "name_en": "Eritrea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "EST",
    "name_zh": "爱沙尼亚",
    "name_en": "Estonia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ETH",
    "name_zh": "埃塞俄比亚",
    "name_en": "Ethiopia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "FJI",
    "name_zh": "斐济",
    "name_en": "Fiji",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "FIN",
    "name_zh": "芬兰",
    "name_en": "Finland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "FRA",
    "name_zh": "法国",
    "name_en": "France",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GAB",
    "name_zh": "加蓬",
    "name_en": "Gabon",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GMB",
    "name_zh": "冈比亚",
    "name_en": "Gambia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GEO",
    "name_zh": "格鲁吉亚",
    "name_en": "Georgia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "DEU",
    "name_zh": "德国",
    "name_en": "Germany",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GHA",
    "name_zh": "加纳",
    "name_en": "Ghana",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GRC",
    "name_zh": "希腊",
    "name_en": "Greece",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GRD",
    "name_zh": "格林纳达",
    "name_en": "Grenada",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GTM",
    "name_zh": "危地马拉",
    "name_en": "Guatemala",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GNB",
    "name_zh": "几内亚比绍",
    "name_en": "Guinea-Bissau",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GIN",
    "name_zh": "几内亚",
    "name_en": "Guinea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GUY",
    "name_zh": "圭亚那",
    "name_en": "Guyana",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "HTI",
    "name_zh": "海地",
    "name_en": "Haiti",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "HND",
    "name_zh": "洪都拉斯",
    "name_en": "Honduras",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "HUN",
    "name_zh": "匈牙利",
    "name_en": "Hungary",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ISL",
    "name_zh": "冰岛",
    "name_en": "Iceland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "IND",
    "name_zh": "印度",
    "name_en": "India",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "IDN",
    "name_zh": "印度尼西亚",
    "name_en": "Indonesia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "IRN",
    "name_zh": "伊朗",
    "name_en": "Iran",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "IRQ",
    "name_zh": "伊拉克",
    "name_en": "Iraq",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "IRL",
    "name_zh": "爱尔兰",
    "name_en": "Ireland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ISR",
    "name_zh": "以色列",
    "name_en": "Israel",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ITA",
    "name_zh": "意大利",
    "name_en": "Italy",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CIV",
    "name_zh": "科特迪瓦",
    "name_en": "Cote D'Ivoire",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "JAM",
    "name_zh": "牙买加",
    "name_en": "Jamaica",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "JPN",
    "name_zh": "日本",
    "name_en": "Japan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "JOR",
    "name_zh": "约旦",
    "name_en": "Jordan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KAZ",
    "name_zh": "哈萨克斯坦",
    "name_en": "Kazakhstan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KEN",
    "name_zh": "肯尼亚",
    "name_en": "Kenya",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KIR",
    "name_zh": "基里巴斯",
    "name_en": "Kiribati",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "XKX",
    "name_zh": "科索沃",
    "name_en": "Kosovo",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KWT",
    "name_zh": "科威特",
    "name_en": "Kuwait",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KGZ",
    "name_zh": "吉尔吉斯斯坦",
    "name_en": "Kyrgyzstan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LAO",
    "name_zh": "老挝",
    "name_en": "Lao PDR",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LVA",
    "name_zh": "拉脱维亚",
    "name_en": "Latvia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LBN",
    "name_zh": "黎巴嫩",
    "name_en": "Lebanon",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LSO",
    "name_zh": "莱索托",
    "name_en": "Lesotho",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LBR",
    "name_zh": "利比里亚",
    "name_en": "Liberia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LBY",
    "name_zh": "利比亚",
    "name_en": "Libya",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LIE",
    "name_zh": "列支敦士登",
    "name_en": "Liechtenstein",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LTU",
    "name_zh": "立陶宛",
    "name_en": "Lithuania",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LUX",
    "name_zh": "卢森堡",
    "name_en": "Luxembourg",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MDG",
    "name_zh": "马达加斯加",
    "name_en": "Madagascar",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MWI",
    "name_zh": "马拉维",
    "name_en": "Malawi",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MYS",
    "name_zh": "马来西亚",
    "name_en": "Malaysia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MDV",
    "name_zh": "马尔代夫",
    "name_en": "Maldives",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MLI",
    "name_zh": "马里",
    "name_en": "Mali",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MLT",
    "name_zh": "马耳他",
    "name_en": "Malta",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MHL",
    "name_zh": "马绍尔群岛",
    "name_en": "Marshall Islands",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MDA",
    "name_zh": "摩尔多瓦",
    "name_en": "Moldova",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MRT",
    "name_zh": "毛里塔尼亚",
    "name_en": "Mauritania",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MUS",
    "name_zh": "毛里求斯",
    "name_en": "Mauritius",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MEX",
    "name_zh": "墨西哥",
    "name_en": "Mexico",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MCO",
    "name_zh": "摩纳哥",
    "name_en": "Monaco",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MNG",
    "name_zh": "蒙古",
    "name_en": "Mongolia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MNE",
    "name_zh": "黑山",
    "name_en": "Montenegro",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MAR",
    "name_zh": "摩洛哥",
    "name_en": "Morocco",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MOZ",
    "name_zh": "莫桑比克",
    "name_en": "Mozambique",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MMR",
    "name_zh": "缅甸",
    "name_en": "Myanmar",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NAM",
    "name_zh": "纳米比亚",
    "name_en": "Namibia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NRU",
    "name_zh": "瑙鲁",
    "name_en": "Nauru",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NPL",
    "name_zh": "尼泊尔",
    "name_en": "Nepal",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NLD",
    "name_zh": "荷兰",
    "name_en": "Netherlands",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NZL",
    "name_zh": "新西兰",
    "name_en": "New Zealand",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NIC",
    "name_zh": "尼加拉瓜",
    "name_en": "Nicaragua",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NER",
    "name_zh": "尼日尔",
    "name_en": "Niger",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NGA",
    "name_zh": "尼日利亚",
    "name_en": "Nigeria",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NIU",
    "name_zh": "纽埃",
    "name_en": "Niue",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PRK",
    "name_zh": "朝鲜",
    "name_en": "DPR Korea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "NOR",
    "name_zh": "挪威",
    "name_en": "Norway",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "OMN",
    "name_zh": "阿曼",
    "name_en": "Oman",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PAK",
    "name_zh": "巴基斯坦",
    "name_en": "Pakistan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PSE",
    "name_zh": "巴勒斯坦",
    "name_en": "Palestine",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PLW",
    "name_zh": "帕劳",
    "name_en": "Palau",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PAN",
    "name_zh": "巴拿马",
    "name_en": "Panama",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PNG",
    "name_zh": "巴布亚新几内亚",
    "name_en": "Papua New Guinea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PRY",
    "name_zh": "巴拉圭",
    "name_en": "Paraguay",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PER",
    "name_zh": "秘鲁",
    "name_en": "Peru",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PHL",
    "name_zh": "菲律宾",
    "name_en": "Philippines",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "PRT",
    "name_zh": "葡萄牙",
    "name_en": "Portugal",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "POL",
    "name_zh": "波兰",
    "name_en": "Poland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "QAT",
    "name_zh": "卡塔尔",
    "name_en": "Qatar",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "MKD",
    "name_zh": "马其顿",
    "name_en": "Republic of North Macedonia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "COG",
    "name_zh": "刚果共和国",
    "name_en": "Republic of the Congo",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ROU",
    "name_zh": "罗马尼亚",
    "name_en": "Romania",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "RUS",
    "name_zh": "俄罗斯联邦",
    "name_en": "Russian Federation",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "RWA",
    "name_zh": "卢旺达",
    "name_en": "Rwanda",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KNA",
    "name_zh": "圣基茨和尼维斯",
    "name_en": "Saint Kitts and Nevis",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "WSM",
    "name_zh": "萨摩亚",
    "name_en": "Samoa",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SMR",
    "name_zh": "圣马力诺",
    "name_en": "San Marino",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "STP",
    "name_zh": "圣多美和普林西比",
    "name_en": "Sao Tome and Principe",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SAU",
    "name_zh": "沙特阿拉伯",
    "name_en": "Saudi Arabia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SEN",
    "name_zh": "塞内加尔",
    "name_en": "Senegal",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SRB",
    "name_zh": "塞尔维亚",
    "name_en": "Serbia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SYC",
    "name_zh": "塞舌尔",
    "name_en": "Seychelles",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SLE",
    "name_zh": "塞拉利昂",
    "name_en": "Sierra Leone",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SGP",
    "name_zh": "新加坡",
    "name_en": "Singapore",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SVK",
    "name_zh": "斯洛伐克",
    "name_en": "Slovakia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SVN",
    "name_zh": "斯洛文尼亚",
    "name_en": "Slovenia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SLB",
    "name_zh": "所罗门群岛",
    "name_en": "Solomon Islands",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SOM",
    "name_zh": "索马里",
    "name_en": "Somalia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ZAF",
    "name_zh": "南非",
    "name_en": "South Africa",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "KOR",
    "name_zh": "韩国",
    "name_en": "Republic of Korea",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SSD",
    "name_zh": "南苏丹",
    "name_en": "South Sudan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ESP",
    "name_zh": "西班牙",
    "name_en": "Spain",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LKA",
    "name_zh": "斯里兰卡",
    "name_en": "Sri Lanka",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "LCA",
    "name_zh": "圣卢西亚",
    "name_en": "Saint Lucia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "VCT",
    "name_zh": "圣文森特和格林纳丁斯",
    "name_en": "Saint Vincent and the Grenadines",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SDN",
    "name_zh": "苏丹",
    "name_en": "Sudan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SUR",
    "name_zh": "苏里南",
    "name_en": "Suriname",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SWZ",
    "name_zh": "斯威士兰",
    "name_en": "Swaziland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SWE",
    "name_zh": "瑞典",
    "name_en": "Sweden",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "CHE",
    "name_zh": "瑞士",
    "name_en": "Switzerland",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "SYR",
    "name_zh": "叙利亚",
    "name_en": "Syria",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TJK",
    "name_zh": "塔吉克斯坦",
    "name_en": "Tajikistan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TZA",
    "name_zh": "坦桑尼亚",
    "name_en": "Tanzania",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "THA",
    "name_zh": "泰国",
    "name_en": "Thailand",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TGO",
    "name_zh": "多哥",
    "name_en": "Togo",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TKL",
    "name_zh": "托克劳",
    "name_en": "Tokelau",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TON",
    "name_zh": "汤加",
    "name_en": "Tonga",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TTO",
    "name_zh": "特立尼达和多巴哥",
    "name_en": "Trinidad and Tobago",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TUN",
    "name_zh": "突尼斯",
    "name_en": "Tunisia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TUR",
    "name_zh": "土耳其",
    "name_en": "Turkey",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TKM",
    "name_zh": "土库曼斯坦",
    "name_en": "Turkmenistan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "TUV",
    "name_zh": "图瓦卢",
    "name_en": "Tuvalu",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "UGA",
    "name_zh": "乌干达",
    "name_en": "Uganda",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "UKR",
    "name_zh": "乌克兰",
    "name_en": "Ukraine",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ARE",
    "name_zh": "阿拉伯联合酋长国",
    "name_en": "United Arab Emirates",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "USA",
    "name_zh": "美国",
    "name_en": "United States",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "GBR",
    "name_zh": "英国",
    "name_en": "United Kingdom",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "URY",
    "name_zh": "乌拉圭",
    "name_en": "Uruguay",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "UZB",
    "name_zh": "乌兹别克斯坦",
    "name_en": "Uzbekistan",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "VUT",
    "name_zh": "瓦努阿图",
    "name_en": "Vanuatu",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "VAT",
    "name_zh": "梵蒂冈",
    "name_en": "Vatican",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "VEN",
    "name_zh": "委内瑞拉",
    "name_en": "Venezuela",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "VNM",
    "name_zh": "越南",
    "name_en": "Viet Nam",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "YEM",
    "name_zh": "也门",
    "name_en": "Yemen",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ZMB",
    "name_zh": "赞比亚",
    "name_en": "Zambia",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
  {
    "code": "ZWE",
    "name_zh": "津巴布韦",
    "name_en": "Zimbabwe",
    "status": "A",
    "on_GSL": false,
    "vote_status": "",
    "power_status": "Delegate",
    "on_mod": false
  },
]
export default countries