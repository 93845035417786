import * as AV from 'leancloud-storage';

//正式库 npm build 时请使用这个
const APP_ID = 'KkrjlYOiuCiVnGpv8kBs5TQq-gzGzoHsz';
const APP_KEY = 'tXhXCHOmF5BcSUwfwfklKV4O';

AV.init({
  appId: APP_ID,
  appKey: APP_KEY,
  serverURL: 'https://sessionapi.thindek.com',
});
// AV.debug.enable()
AV.debug.disable();
export default AV;
