import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/about',
    meta: {
      // set to TRUE when building the electron app
      // requiresAuth: true,
      requiresAuth: false,
    },
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: 'setup',
        name: 'setup',
        component: () => import('../views/setup.vue'),
      },
      {
        path: 'analytics',
        name: 'analytics',
        component: () => import('../views/analytics.vue'),
      },
      {
        path: 'rollCall',
        name: 'rollCall',
        component: () => import('../views/rollCall.vue'),
      },
      {
        path: 'gsl',
        name: 'gsl',
        component: () => import('../views/gsl.vue'),
      },
      {
        path: 'motion',
        name: 'motion',
        component: () => import('../views/motion.vue'),
      },
      {
        path: 'mod',
        name: 'mod',
        props: true,
        component: () => import('../views/mod.vue'),
      },
      {
        path: 'unmod',
        name: 'unmod',
        props: true,
        component: () => import('../views/unmod.vue'),
      },
      {
        path: 'speaker',
        name: 'speaker',
        component: () => import('../views/speaker.vue'),
      },
      {
        path: 'vote',
        name: 'vote',
        component: () => import('../views/vote.vue'),
      },
    ],
  },
  {
    path: '/history',
    name: 'history',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "history" */ '../views/history.vue'),
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      requiresAuth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/mpcview/:id',
    name: 'mpcview',
    props: true,
    component: () => import('../views/mpcView.vue'),
  },
  {
    path: '/mpc',
    name: 'mpc',
    component: () => import('../views/mpc.vue'),
  },
  {
    path: '/mpc/publish/:id/:encodedKey',
    name: 'mpcPublish',
    props: true,
    component: () => import('../views/mpcPublish.vue'),
  },
  {
    path: '/account',
    name: 'account',
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/account.vue'),
  },
  // {
  //   path: '/404',
  //   name: 'notFound',
  //   component: () => import('../views/notFound.vue'),
  // },
];

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes: routes,
  // 返回页面时回到上次浏览位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.pro) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});
export default router;
