import Vue from 'vue';
import Vuex from 'vuex';
import countries from '../assets/countries';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
import db from '@/plugins/leancloud';

export default new Vuex.Store({
  state: {
    confsStored: [],
    currentConfId: '',
    currentSession: {
      outSessionCountries: [],
      // 主发言人名单
      speakingList: [],
      // 动议名单
      motionList: [],
      // 委员会名称
      committee: '',
      // 会场名称
      agenda: '',
      //该场委员会的国家
      inSessionCountries: [],
      speakingListHistory: [],
      modHistory: [],
      unmodHistory: [],
      voteHistory: [],
      motionHistory: [],
      gslTimer: {
        min: 2,
        sec: 0,
      },
      currentMod: {
        durationMin: 10,
        durationSec: 0,
        indDurationMin: 1,
        indDurationSec: 0,
        country: {
          // code: "AFG",
          // name_zh: "阿富汗",
          // name_en: "Afghanistan",
          // status: "PV",
          // on_GSL: false,
          // vote_status: "abstain",
          // power_status: "Delegate",
          // on_mod: false,
        },
        topic: '',
        type: 'mod',
      },
      currentUnmod: {
        durationMin: 10,
        durationSec: 0,
        country: {
          code: 'AFG',
          name_zh: '阿富汗',
          name_en: 'Afghanistan',
          status: 'PV',
          on_GSL: false,
          vote_status: 'abstain',
          power_status: 'Delegate',
          on_mod: false,
        },
        type: 'unmod',
      },
    },
    user: null,
    pro: false,
    theme: '#398961',
    // 会议名字
    conferenceName: '',
    // 是否展示Session字样
    showSession: true,
    countries: JSON.parse(JSON.stringify(countries)),
    unmodtimerState: '',
    // 主发言名单时间
  },
  mutations: {
    set_pro(state, payload) {
      state.pro = payload;
    },
    setConfId(state, payload) {
      state.currentConfId = payload;
    },
    clearCurrentSession(state) {
      state.currentSession = {
        speakingList: [],
        motionList: [],
        inSessionCountries: [],
        speakingListHistory: [],
        modHistory: [],
        unmodHistory: [],
        voteHistory: [],
        motionHistory: [],
        outSessionCountries: JSON.parse(JSON.stringify(countries)),
        gslTimer: {
          min: 2,
          sec: 0,
        },
        currentMod: {
          durationMin: 10,
          durationSec: 0,
          indDurationMin: 1,
          indDurationSec: 0,
          country: {
            // code: "AFG",
            // name_zh: "阿富汗",
            // name_en: "Afghanistan",
            // status: "PV",
            // on_GSL: false,
            // vote_status: "abstain",
            // power_status: "Delegate",
            // on_mod: false,
          },
          topic: '',
          type: 'mod',
        },
        currentUnmod: {
          durationMin: 10,
          durationSec: 0,
          country: {
            code: 'AFG',
            name_zh: '阿富汗',
            name_en: 'Afghanistan',
            status: 'PV',
            on_GSL: false,
            vote_status: 'abstain',
            power_status: 'Delegate',
            on_mod: false,
          },
          type: 'unmod',
        },
      };
    },
    set_user(state, payload) {
      state.user = payload;
    },
    setCurrentSession(state, payload) {
      state.currentSession = payload;
    },
    setConferenceName(state, payload) {
      state.conferenceName = payload;
    },
    setTheme(state, payload) {
      state.theme = payload;
    },
    setShowSession(state, payload) {
      state.showSession = payload;
    },
    saveMotion(state, payload) {
      var motion = state.currentSession.motionList[0];
      motion.status = payload;
      state.currentSession.motionHistory.push(motion);
    },
    initializeOutSessionCountries(state) {
      state.currentSession.outSessionCountries = JSON.parse(
        JSON.stringify(countries)
      );
    },
    setCommittee(state, payload) {
      state.currentSession.committee = payload;
    },
    setGslTimer(state, payload) {
      state.currentSession.gslTimer = payload;
    },
    setAgenda(state, payload) {
      state.currentSession.agenda = payload;
    },
    // add countries to the current session
    addToSession(state, payload) {
      let index = state.currentSession.outSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.outSessionCountries.splice(index, 1);
      state.currentSession.inSessionCountries.push(payload);
    },
    deleteInsessionCountries(state, payload) {
      state.currentSession.inSessionCountries.splice(payload, 1);
    },
    pushOutSessionCountries(state, payload) {
      state.currentSession.outSessionCountries.push(payload);
    },
    addCustomToSession(state, payload) {
      state.currentSession.inSessionCountries.push(payload);
    },
    deleteAllInSession(state) {
      state.currentSession.inSessionCountries = [];
      state.currentSession.outSessionCountries = JSON.parse(
        JSON.stringify(countries)
      );
    },
    // role call, determine which countries are present
    setStatus(state, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.item.code
      );
      state.currentSession.inSessionCountries[index].status = payload.status;
    },
    setAllAbsent(state) {
      state.currentSession.inSessionCountries.forEach((country) => {
        country.status = 'A';
      });
    },
    setAllPresent(state) {
      state.currentSession.inSessionCountries.forEach((country) => {
        country.status = 'P';
      });
    },
    setAllPresentVoting(state) {
      state.currentSession.inSessionCountries.forEach((country) => {
        country.status = 'PV';
      });
    },
    addToSpeakingList(state, payload) {
      state.currentSession.speakingList.push(payload);
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[index].on_GSL = true;
    },
    removeSpeakingList(state, payload) {
      let index = state.currentSession.speakingList.findIndex(
        (x) => x.code === payload.code
      );
      let indexSession = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[indexSession].on_GSL = false;
      state.currentSession.speakingList.splice(index, 1);
    },
    // next speaker on gsl
    nextSpeaker(state) {
      const speaker = JSON.parse(
        JSON.stringify(state.currentSession.speakingList[0])
      );
      speaker.gslTimer = state.currentSession.gslTimer;
      state.currentSession.speakingListHistory.push(speaker);
      state.currentSession.speakingList.splice(0, 1);
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === state.currentSession.speakingList[0].code
      );
      state.currentSession.inSessionCountries[index].on_GSL = false;
    },
    resetHistory(state) {
      state.currentSession.speakingListHistory = [];
      state.currentSession.modHistory = [];
      state.currentSession.unmodHistory = [];
      state.currentSession.voteHistory = [];
      state.currentSession.motionHistory = [];
    },
    setUnmodtimerState(state, payload) {
      state.currentSession.unmodtimerState = payload;
    },
    resetMotionList(state) {
      state.currentSession.motionList = [];
    },
    setMotionList(state, payload) {
      state.currentSession.motionList.push(payload);
    },
    popMotion(state) {
      state.currentSession.motionList.splice(0, 1);
    },
    setMod(state, payload) {
      state.currentSession.currentMod = payload;
    },
    setUnmod(state, payload) {
      state.currentSession.currentUnmod = payload;
    },
    voteFor(state, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[index].vote_status = 'for';
    },
    voteAgainst(state, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[index].vote_status = 'against';
    },
    voteAbstain(state, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[index].vote_status = 'abstain';
    },
    deleteConf(state, payload) {
      let index = state.confsStored.findIndex((x) => x.id === payload);
      console.log(index);
      state.confsStored.splice(index, 1);
    },
    votePass(state, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      state.currentSession.inSessionCountries[index].vote_status = 'pass';
    },
    setconfsStored(state, payload) {
      state.confsStored = payload;
    },
    resetVote(state) {
      state.currentSession.inSessionCountries.forEach((e) => {
        e.vote_status = '';
      });
    },
  },
  actions: {
    newConf({ commit }) {
      return new Promise((resolve, reject) => {
        const sessionConferences = db.Object.extend('sessionConferences');
        const conf = new sessionConferences();
        conf.set('dependent', db.User.current());
        conf
          .save()
          .then((resp) => {
            commit('setConfId', resp.id);
            commit('clearCurrentSession');
            resolve(resp.id);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    fetchConferences({ commit }) {
      const query = new db.Query('sessionConferences');
      query.equalTo('dependent', db.User.current());
      query.equalTo('deleted', false);
      query.find().then((confs) => {
        commit('setconfsStored', confs);
      });
    },
    // resetHistory({ commit, dispatch }) {
    //   commit('resetHistory');
    //   dispatch('updateSession');
    // },
    updateSession({ state }) {
      console.log('saving progress');
      if (state.currentConfId) {
        const obj = db.Object.createWithoutData(
          'sessionConferences',
          state.currentConfId
        );
        let data = JSON.parse(JSON.stringify(state.currentSession));
        delete data.objectId;
        delete data.createdAt;
        delete data.updatedAt;
        console.log(data);
        obj.set(data);
        obj.save().catch((err) => {
          console.log(err);
        });
      }
    },
    deleteConf({ commit }, payload) {
      const obj = db.Object.createWithoutData('sessionConferences', payload);
      commit('deleteConf', payload);
      obj.set('deleted', true);
      obj.save().catch((err) => {
        console.log(err);
      });
    },
    deleteInSessionCountry({ state, commit, getters }, payload) {
      let index = state.currentSession.inSessionCountries.findIndex(
        (x) => x.code === payload.code
      );
      commit('deleteInsessionCountries', index);
      if (getters.countryCodes.includes(payload.code)) {
        commit('pushOutSessionCountries', payload);
      }
    },
    async userLogin({ commit }, payload) {
      try {
        let login = await db.User.logIn(payload.email, payload.password);
        commit('set_pro', db.User.current().toJSON().pro);
        commit('set_user', db.User.current().toJSON());
        return login;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        db.User.logOut()
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
        commit('set_pro', false);
        commit('set_user', null);
        commit('setConferenceName', '');
      });
    },
  },
  getters: {
    isPro: (state) => {
      if (state.user && state.pro) {
        return true;
      } else return false;
    },
    countryCodes: (state) => {
      var codes = [];
      state.countries.forEach((country) => {
        codes.push(country.code);
      });
      return codes;
    },
    //return all countries in alphabetical order
    sortedInSessionCountries: (state) => {
      //remember sort mutates the original array so, copy it first with a spread.
      return [...state.currentSession.inSessionCountries].sort((a, b) =>
        a.name_en.localeCompare(b.name_en)
      );
    },
    sortedOutSessionCountries: (state) => {
      return [...state.currentSession.outSessionCountries].sort((a, b) =>
        a.name_en.localeCompare(b.name_en)
      );
    },
    // calculate majorities of current session
    presentStat: (state) => {
      const arr = state.currentSession.inSessionCountries;
      const all = arr.filter((x) => x.status === 'P' || x.status === 'PV')
        .length;
      const half = Math.floor(all / 2) + 1;
      const twoThirds = Math.ceil((all * 2) / 3);
      const oneFifth = Math.ceil(all / 5);
      return [all, twoThirds, half, oneFifth];
    },
    // calculate voting stat
    voteDocumentMajority: (state, getters) => {
      const arr = getters.voteStat;
      const voteFor = arr[0];
      const voteAgainst = arr[1];
      const abstain = arr[2];
      const twoThirds = Math.ceil(((voteFor + voteAgainst) * 2) / 3);
      const isAbstainOver = abstain > getters.presentStat[2];
      return [twoThirds, isAbstainOver];
    },
    voteStat: (state, getters) => {
      const arr = getters.inSessionCountriesPresent;
      const all = arr.length;
      const voteFor = arr.filter((x) => x.vote_status === 'for').length;
      const voteAgainst = arr.filter((x) => x.vote_status === 'against').length;
      const abstain = arr.filter((x) => x.vote_status === 'abstain').length;
      return [voteFor, voteAgainst, abstain, all];
    },
    // filter present and present and voting countries to add to GSL, motion, unmod, and mod list
    inSessionCountriesPresent: (state) => {
      return [...state.currentSession.inSessionCountries].filter(
        (x) => x.status === 'P' || x.status === 'PV'
      );
    },
    inSessionCountriesEmpty: (state) => {
      const val = state.currentSession.inSessionCountries.length === 0;
      return val;
    },
    countriesVotedPass: (state) => {
      const val = state.currentSession.inSessionCountries.filter(
        (x) => x.vote_status === 'pass'
      );
      return val;
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
